import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Button from "../../components/button";
import SplitHero from "../../components/split-hero";
import Tag, { TagGroup } from "../../components/tag";
import Text from "../../components/text";

function SCTimelineSection() {
  const { vcScreenshot2 } = useStaticQuery(
    graphql`
      query {
        vcScreenshot2: file(relativePath: { eq: "vc-screenshot-2.jpg" }) {
          ...fluidImage
        }
      }
    `
  );

  return (
    <SplitHero>
      <SplitHero.Media>
        <SplitHero.Media.Content>
          <Img
            fluid={vcScreenshot2.childImageSharp.fluid}
            alt="Safeguarding Children Timeline"
          />
        </SplitHero.Media.Content>
      </SplitHero.Media>

      <SplitHero.Content>
        <SplitHero.Heading>
          <Text variant="h3" component="h2" shade="light">
            Safeguarding Children Timeline.
          </Text>
        </SplitHero.Heading>

        <Text variant="p" shade="mid">
          An interactive 3D timeline that teaches the history of safeguarding
          children legislation in the UK.
          <TagGroup>
            <Tag>3D</Tag>
            <Tag>Animation</Tag>
            <Tag>Babylon.js</Tag>
            <Tag>Javascript</Tag>
            <Tag>WebGL</Tag>
          </TagGroup>
        </Text>

        <Button
          href="https://www.virtual-college.co.uk/safeguarding-children-timeline/"
          as="a"
          target="__blank"
          rel="nofollow"
          marginTop={4}
          small
        >
          View Timeline
        </Button>
      </SplitHero.Content>
    </SplitHero>
  );
}

export default SCTimelineSection;
