import styled, { css } from 'styled-components';
import { spacing } from '@material-ui/system';

const Button = styled.button`
  ${({ small, red, theme }) => css`
    align-items: center;
    background-color: transparent;
    display: inline-flex;
    color: ${theme.palette.text.dark};
    cursor: pointer;
    flex: none;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    padding: ${theme.spacing(3)}px ${theme.spacing(5)}px;
    position: relative;
    z-index: 2;
    vertical-align: top;

    &:after {
      background-color: ${theme.palette.background.white};
      border-radius: 6px;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: transform 200ms ease;
      transform-origin: 50% 50%;
      z-index: -1;
    }

    &:hover:after {
      transform: scale(1.1) translateZ(0);
    }

    &:active:after {
      transform: scale(1.05) translateZ(0);
    }

    ${small &&
      css`
        font-size: 18px;
        padding: ${theme.spacing(2)}px ${theme.spacing(3)}px;
      `}

    ${red &&
      css`
        color: ${theme.palette.background.red};
      `}

    ${spacing}
  `}
`;

export default Button;
